import { ReactNode, useMemo, useState } from "react";
import { ContactDetails } from "../components/application/ContactDetails";
import { PaymentDetails } from "../components/application/PaymentDetails";
import { PersonalDetails } from "../components/application/PersonalDetails";
import { SocialDetails } from "../components/application/SocialDetails";
import { Application } from "../schemas/applications";
import { stepType } from "../schemas/types";
import { Button, CircularProgress } from "@nextui-org/react";
import { BiArrowBack } from "react-icons/bi";
import { ApplicationDetails } from "../components/application/ApplicationDetails";
import { ProfileDetails } from "../components/application/ProfileDetails";
import { useMutation } from "react-query";
import { UPLOAD } from "../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ApplicationForm() {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [formData, setFormData] = useState<Application | null>();
  const { mutate, isLoading } = useMutation(UPLOAD, {
    onSuccess(res) {
      const data = res.data;

      if (data.success) {
        return navigate("/submitted", { state: data.data });
      }

      toast.error(data.message);
    },

    onError(error: any) {
      toast.error(error.response.data.message);
    },
  });
  const steps: { type: stepType; description?: string; element: ReactNode }[] =
    useMemo(() => {
      function handleSubmitted(data: any, next: stepType) {
        setFormData((pre) => ({ ...pre, ...data }));
        const index = steps.findIndex((s) => s.type === next);

        if (index < 0) return;

        if (steps[index].type === "finish" && data.order) {
          const { order, ...applicant } = data;

          if (order?.status === "COMPLETED") {
            toast.success("Payment completed successfully");
            const form = new FormData();
            const submitted = { ...applicant, orderId: order.id };
            const keys = Object.keys(submitted);

            keys.forEach((key) => {
              form.append(key, submitted[key]);
            });

            return mutate({
              path: "/apply",
              data: form,
            });
          }

          toast.error(`Payment was ${order.status}`);
        }

        return setStep(index);
      }

      return [
        {
          type: "biographic",
          element: (
            <PersonalDetails
              onSubmitted={handleSubmitted}
              defaultData={formData!}
            />
          ),
        },
        {
          type: "contact",
          element: (
            <ContactDetails
              onSubmitted={handleSubmitted}
              defaultData={formData!}
            />
          ),
        },
        {
          type: "social",
          element: (
            <SocialDetails
              onSubmitted={handleSubmitted}
              defaultData={formData!}
            />
          ),
        },
        {
          type: "profile",
          element: (
            <ProfileDetails
              onSubmitted={handleSubmitted}
              defaultData={formData!}
            />
          ),
        },
        {
          type: "review",
          description:
            "Please review your information before proceeding to payment",
          element: (
            <ApplicationDetails
              details={formData!}
              onSubmitted={handleSubmitted}
            />
          ),
        },
        {
          type: "payment",
          description: "Complete payment to proceed",
          element: (
            <PaymentDetails
              applicant={formData!}
              onSubmitted={handleSubmitted}
            />
          ),
        },
        {
          type: "finish",
          element: <></>,
        },
      ];
    }, [formData, mutate]);

  const currentStep = useMemo(() => steps[step], [step, steps]);

  return (
    <div className="bg-gray-100 min-h-screen py-3">
      <div className="container max-w-2xl">
        <div className="flex gap-3 mb-1 px-4 md:px-0 items-center border-b md:border-none">
          <img
            className="rounded w-[45px] md:w-[30px]"
            src="/logo512.png"
            alt="App Logo"
          />
          <h1 className="text-primary text-2xl md:text-xl font-bold">
            House of Celebs Africa
          </h1>
        </div>

        <div className="my-6 px-4">
          <div className="mb-4">
            <div className="flex gap-1 items-center">
              {step > 0 && (
                <Button
                  isIconOnly={true}
                  variant="faded"
                  size="sm"
                  className="border-none p-0 m-0"
                  onPress={() => setStep((pre) => (pre > 0 ? pre - 1 : pre))}
                >
                  <BiArrowBack className="m-0" size={16} />
                </Button>
              )}
              <p className="text-xs uppercase font-black text-slate-500">
                step {step + 1} of {steps.length - 1}
              </p>
            </div>
            <h2 className="text-lg font-bold capitalize">
              {currentStep?.type} Information
            </h2>
            <p className="text-sm text-gray-600">
              {currentStep.description ??
                `Please provide your ${currentStep.type} information by completing the fields below`}
            </p>
          </div>
          {isLoading ? (
            <div className="flex sm:justify-center items-center gap-2 py-5">
              <CircularProgress />
              <p className="font-semibold text-slate-500">
                Submitting application details
              </p>
            </div>
          ) : (
            currentStep?.element
          )}
        </div>
      </div>
    </div>
  );
}
