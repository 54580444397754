import { Button } from "@nextui-org/react";
import React from "react";

export const NextButton = ({ isSubmitting }: { isSubmitting?: boolean }) => {
  return (
    <div className="flex flex-col mt-6 items-center">
      <Button
        isLoading={isSubmitting}
        type="submit"
        color="primary"
        size="lg"
        radius="sm"
      >
        <strong>Next</strong>
      </Button>
    </div>
  );
};
