import { stepType } from "../../schemas/types";
import {
  ContactDetailsSchema,
  ContactDetailsType,
} from "../../schemas/applications.d";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { InputField } from "../InputField";
import { NextButton } from "./NextButton";

export const ContactDetails = ({
  onSubmitted,
  defaultData,
}: {
  defaultData: ContactDetailsType;
  onSubmitted: (data: ContactDetailsType, step: stepType) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactDetailsType>({
    resolver: zodResolver(ContactDetailsSchema),
  });

  function onSubmit(data: ContactDetailsType) {
    onSubmitted(data, "social");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="gap-2 flex flex-col">
      <address className="flex flex-col gap-2">
        <InputField
          autoFocus={true}
          label="Street"
          isInvalid={Boolean(errors.street)}
          errorMessage={errors.street?.message}
          defaultValue={defaultData?.street}
          {...register("street", { required: true })}
        />
        <InputField
          label="City/Town"
          isInvalid={Boolean(errors.town)}
          errorMessage={errors.town?.message}
          defaultValue={defaultData?.town}
          {...register("town", { required: true })}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <div className="col-span-1">
            <InputField
              label="State/Province"
              isInvalid={Boolean(errors.state)}
              errorMessage={errors.state?.message}
              defaultValue={defaultData?.state}
              {...register("state", { required: true })}
            />
          </div>
          <div className="col-span-1">
            <InputField
              label="Zip code"
              isInvalid={Boolean(errors.zipCode)}
              errorMessage={errors.zipCode?.message}
              defaultValue={defaultData?.zipCode}
              {...register("zipCode")}
            />
          </div>
        </div>
        <InputField
          label="Country"
          isInvalid={Boolean(errors.country)}
          errorMessage={errors.country?.message}
          defaultValue={defaultData?.country}
          {...register("country", { required: true })}
        />
      </address>

      <InputField
        type="telephone"
        label="Phone number"
        isInvalid={Boolean(errors.phone)}
        errorMessage={errors.phone?.message}
        defaultValue={defaultData?.phone}
        {...register("phone", { required: true })}
      />

      <InputField
        type="email"
        label="Email address"
        isInvalid={Boolean(errors.email)}
        errorMessage={errors.email?.message}
        defaultValue={defaultData?.email}
        {...register("email", { required: true })}
      />

      <NextButton isSubmitting={isSubmitting} />
    </form>
  );
};
