import { Image } from "@nextui-org/react";
import { stepType } from "../../schemas/types";
import { ProfileDetailsType } from "../../schemas/applications.d";
import { NextButton } from "./NextButton";
import { useState } from "react";

export const ProfileDetails = ({
  onSubmitted,
  defaultData,
}: {
  defaultData: ProfileDetailsType;
  onSubmitted: (data: ProfileDetailsType, step: stepType) => void;
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState<File>(defaultData?.photo);

  function onSubmit(e: any) {
    e.preventDefault();

    onSubmitted({ photo: selectedPhoto! }, "review");
  }

  function handlePhotoSelectionChange(e: any) {
    const target = e.target;

    if (target.files.length > 0) {
      return setSelectedPhoto(target.files[0]);
    }
  }

  return (
    <>
      <div className="flex flex-col gap-2 sm:items-start">
        <p className="font-semibold">Upload a profile picture</p>
        {selectedPhoto && (
          <Image
            src={URL.createObjectURL(selectedPhoto)}
            alt="profile_image"
            className="object-contain p-1 border w-full border-slate-500"
          />
        )}
        <label
          htmlFor="photo"
          className="border border-slate-500 rounded px-3 py-2 cursor-pointer bg-slate-500 text-white flex justify-center uppercase font-semibold hover:bg-opacity-90"
        >
          {selectedPhoto ? "Change" : "choose"} photo
        </label>
      </div>
      <form
        onSubmit={onSubmit}
        className="gap-2 col-span-1 sm:col-span-2 flex flex-col"
      >
        <input
          type="file"
          hidden={true}
          accept="image/*"
          id="photo"
          name="photo"
          required={!Boolean(selectedPhoto)}
          onChange={handlePhotoSelectionChange}
        />
        {selectedPhoto && <NextButton />}
      </form>
    </>
  );
};
