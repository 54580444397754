import axios from "axios";

const baseURL = process.env.NODE_ENV === "development" ? "" : "./backend";
const api = axios.create({
  baseURL,
});

export function POST({ path, data }: { path: string; data: any }) {
  return api.post(path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function UPLOAD({ path, data }: { path: string; data: any }) {
  return api.post(path, data);
}
