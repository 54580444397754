export function isPhoneNumber(phone: string) {
  const phoneRegex = new RegExp(
    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
  );

  return phone.match(phoneRegex);
}

export function isNumber(value: string) {
  return value.match(/^(0|[1-9]\d*)(\.\d+)?$/);
}
