import { z } from "zod";
import { isNumber, isPhoneNumber } from "../utils/validator";

export const ProfileDetailsSchema = z.object({
  photo: z.instanceof(File, { message: "Please select a file" }),
  // z.object({
  //   lastModified: z.number(),
  //   lastModifiedDate: z.date(),
  //   name: z.string(),
  //   size: z.number(),
  //   type: z.string(),
  //   webkitRelativePath: z.string(),
  // }),
});

export type ProfileDetailsType = z.infer<typeof ProfileDetailsSchema>;

export const PersonalDetailsSchema = z.object({
  firstName: z
    .string({ required_error: "First name field is required" })
    .min(1, "First name field is required"),
  lastName: z
    .string({ required_error: "Last name field is required" })
    .min(1, "Last name field is required"),
  gender: z.enum(["Female", "Male", "Other"], {
    invalid_type_error: "Specify a gender from the options",
    required_error: "Gender field is required",
  }),
  dob: z.date({
    required_error: "Date of birth field is required",
    invalid_type_error: "Invalid date of birth provided",
    coerce: true,
  }),
  ethnicity: z
    .string({ required_error: "Ethnicity field is required" })
    .min(1, "Ethnicity field is required"),
  maritalStatus: z
    .string({ required_error: "Marital status field is required" })
    .min(1, "Marital status field is required"),
  height: z
    .string({
      required_error: "Height field is required",
    })
    .min(1, "Height field is required")
    .refine((height) => isNumber(height), "Invalid value of height"),
  weight: z
    .string({
      required_error: "Weight field is required",
    })
    .min(1, "Weight field is required")
    .refine((weight) => isNumber(weight), "Invalid value of wight"),
  currentOccupation: z
    .string({ required_error: "Current occupation field is required" })
    .min(1, "Current occupation field is required"),
  countryOfOrigin: z
    .string({ required_error: "Country of origin field is required" })
    .min(1, "Country of origin field is required"),
});

export type PersonalDetailsType = z.infer<typeof PersonalDetailsSchema>;

export const ContactDetailsSchema = z.object({
  email: z
    .string({ required_error: "Email field is required" })
    .min(1, "Email field is required")
    .email("Invalid email address"),
  phone: z
    .string({
      required_error: "Phone number field is required",
    })
    .min(1, "Phone number field is required")
    .refine((phone) => isPhoneNumber(phone), "Invalid phone number"),
  street: z
    .string({ required_error: "Street field is required" })
    .min(1, "Street field is required"),
  town: z
    .string({ required_error: "Town field is required" })
    .min(1, "Town field is required"),
  state: z
    .string({ required_error: "Street field is required" })
    .min(1, "Street field is required"),
  zipCode: z.string().optional(),
  country: z
    .string({ required_error: "Country field is required" })
    .min(1, "Country field is required"),
});

export type ContactDetailsType = z.infer<typeof ContactDetailsSchema>;

export const SocialDetailsSchema = z.object({
  instagramHandle: z
    .string({ required_error: "Instagram handle field is required" })
    .min(1, "Instagram handle field is required"),
  facebookName: z
    .string({ required_error: "Facebook name field is required" })
    .min(1, "Facebook name field is required"),
  tikTokName: z
    .string({ required_error: "Tik-Tok name field is required" })
    .min(1, "Tik-Tok name field is required"),
  description: z
    .string({ required_error: "Tell us about yourself field is required" })
    .min(1, "Tell us about yourself field is required"),
  aboutUsSource: z
    .string({ required_error: "How you hear about us field is required" })
    .min(1, "How you hear about us field is required"),
});

export type SocialDetailsType = z.infer<typeof SocialDetailsSchema>;

export type Application = PersonalDetailsType &
  ContactDetailsType &
  SocialDetailsType &
  ProfileDetailsType & {
    order: any;
  };
