import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Index from "./pages/Index";
import Submitted from "./pages/Submitted";
import Application from "./pages/ApplicationForm";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Index />} />
        <Route path="submitted" element={<Submitted />} />
        <Route path="apply" element={<Application />} />
      </Route>
    </Routes>
  );
}

export default App;
