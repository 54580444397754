import { SelectItem } from "@nextui-org/react";
import { stepType } from "../../schemas/types";
import {
  PersonalDetailsSchema,
  PersonalDetailsType,
} from "../../schemas/applications.d";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { InputField } from "../InputField";
import { SelectField } from "../SelectField";
import { NextButton } from "./NextButton";

export const PersonalDetails = ({
  onSubmitted,
  defaultData,
}: {
  defaultData: PersonalDetailsType;
  onSubmitted: (data: PersonalDetailsType, step: stepType) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PersonalDetailsType>({
    resolver: zodResolver(PersonalDetailsSchema),
  });

  function onSubmit(data: PersonalDetailsType) {
    onSubmitted(data, "contact");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="gap-2 flex flex-col">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <div className="col-span-1">
          <InputField
            label="First name"
            autoFocus={true}
            isInvalid={Boolean(errors.firstName)}
            errorMessage={errors.firstName?.message}
            defaultValue={defaultData?.firstName}
            {...register("firstName", { required: true })}
          />
        </div>
        <div className="col-span-1">
          <InputField
            label="Last name"
            isInvalid={Boolean(errors.lastName)}
            errorMessage={errors.lastName?.message}
            defaultValue={defaultData?.lastName}
            {...register("lastName", { required: true })}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <div className="col-span-1">
          <InputField
            label="Date of Birth"
            type="date"
            isInvalid={Boolean(errors.dob)}
            errorMessage={errors.dob?.message}
            defaultValue={defaultData?.dob?.toDateString()}
            {...register("dob", { required: true })}
          />
        </div>
        <div className="col-span-1">
          <SelectField
            label="Gender"
            isInvalid={Boolean(errors.gender)}
            errorMessage={errors.gender?.message}
            defaultSelectedKeys={[defaultData?.gender]}
            items={[
              { label: "Female", value: "Female" },
              { label: "Male", value: "Male" },
              { label: "Other", value: "Other" },
            ]}
            {...register("gender", { required: true })}
          >
            {(item: any) => (
              <SelectItem key={item.value}>{item.label}</SelectItem>
            )}
          </SelectField>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <div className="col-span-1">
          <InputField
            label="Height in ft"
            isInvalid={Boolean(errors.height)}
            errorMessage={errors.height?.message}
            defaultValue={defaultData?.height}
            {...register("height", { required: true, min: 1 })}
          />
        </div>
        <div className="col-span-1">
          <InputField
            label="Weight in kg"
            isInvalid={Boolean(errors.weight)}
            errorMessage={errors.weight?.message}
            defaultValue={defaultData?.weight}
            {...register("weight", { required: true, min: 1 })}
          />
        </div>
      </div>

      <InputField
        label="Current occupation"
        isInvalid={Boolean(errors.currentOccupation)}
        errorMessage={errors.currentOccupation?.message}
        defaultValue={defaultData?.currentOccupation}
        {...register("currentOccupation", { required: true })}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <div className="col-span-1">
          <InputField
            label="Marital status"
            isInvalid={Boolean(errors.maritalStatus)}
            errorMessage={errors.maritalStatus?.message}
            defaultValue={defaultData?.maritalStatus}
            {...register("maritalStatus", { required: true })}
          />
        </div>
        <div className="col-span-1">
          <InputField
            label="Ethnicity"
            isInvalid={Boolean(errors.ethnicity)}
            errorMessage={errors.ethnicity?.message}
            defaultValue={defaultData?.ethnicity}
            {...register("ethnicity", { required: true })}
          />
        </div>
      </div>

      <InputField
        type="text"
        label="Country of origin"
        isInvalid={Boolean(errors.countryOfOrigin)}
        errorMessage={errors.countryOfOrigin?.message}
        defaultValue={defaultData?.countryOfOrigin}
        {...register("countryOfOrigin", { required: true })}
      />

      <NextButton isSubmitting={isSubmitting} />
    </form>
  );
};
