import { Textarea } from "@nextui-org/react";
import { stepType } from "../../schemas/types";
import {
  SocialDetailsSchema,
  SocialDetailsType,
} from "../../schemas/applications.d";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { InputField } from "../InputField";
import { NextButton } from "./NextButton";

export const SocialDetails = ({
  onSubmitted,
  defaultData,
}: {
  defaultData: SocialDetailsType;
  onSubmitted: (data: SocialDetailsType, step: stepType) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SocialDetailsType>({
    resolver: zodResolver(SocialDetailsSchema),
  });

  function onSubmit(data: SocialDetailsType) {
    onSubmitted(data, "profile");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="gap-2 flex flex-col">
      <InputField
        autoFocus={true}
        label="Instagram handle"
        isInvalid={Boolean(errors.instagramHandle)}
        errorMessage={errors.instagramHandle?.message}
        defaultValue={defaultData?.instagramHandle}
        {...register("instagramHandle", { required: true })}
      />

      <InputField
        label="Facebook name"
        isInvalid={Boolean(errors.facebookName)}
        errorMessage={errors.facebookName?.message}
        defaultValue={defaultData?.facebookName}
        {...register("facebookName", { required: true })}
      />

      <InputField
        label="Tik-Tok name"
        isInvalid={Boolean(errors.tikTokName)}
        errorMessage={errors.tikTokName?.message}
        defaultValue={defaultData?.tikTokName}
        {...register("tikTokName", { required: true })}
      />

      <Textarea
        variant="bordered"
        radius="sm"
        label="About you"
        isInvalid={Boolean(errors.description)}
        placeholder="Write a description of yourself"
        defaultValue={defaultData?.description}
        {...register("description", { required: true })}
        errorMessage={errors.description?.message}
      />

      <InputField
        label="How did you hear about us?"
        isInvalid={Boolean(errors.aboutUsSource)}
        errorMessage={errors.aboutUsSource?.message}
        defaultValue={defaultData?.aboutUsSource}
        {...register("aboutUsSource", { required: true })}
      />

      <NextButton isSubmitting={isSubmitting} />
    </form>
  );
};
