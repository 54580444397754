import { stepType } from "../../schemas/types";
import { Application } from "../../schemas/applications";
import { CreateOrderActions, OnApproveActions } from "@paypal/paypal-js";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { clientID } from "../../utils/utils";

export const PaymentDetails = ({
  onSubmitted,
  applicant,
}: {
  applicant: Application;
  onSubmitted: (data: Application, step: stepType) => void;
}) => {
  const cost = "200.00";

  function onCreateOrder(actions: CreateOrderActions) {
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            value: cost,
            currency_code: "USD",
          },
          description:
            "Payment for online audition form at HOUSE OF CELEBS AFRICA",
        },
      ],
      payment_source: {
        paypal: {
          experience_context: {
            user_action: "PAY_NOW",
            shipping_preference: "NO_SHIPPING",
            landing_page: "LOGIN",
            brand_name: "HOUSE OF CELEBS AFRICA",
            payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
          },
          name: {
            given_name: applicant?.firstName,
            surname: applicant?.lastName,
          },
          email_address: applicant?.email,
        },
      },
    });
  }

  async function onApproveOrder(actions: OnApproveActions) {
    return actions.order!.capture().then((order: any) => {
      onSubmitted({ ...applicant, order }, "finish");

      return order;
    });
  }

  return (
    <div className="flex flex-col">
      <PayPalScriptProvider
        options={{
          currency: "USD",
          intent: "capture",
          clientId: clientID!,
        }}
      >
        <PayPalButtons
          style={{
            layout: "vertical",
            label: "pay",
            tagline: false,
            disableMaxWidth: true,
          }}
          onApprove={(_, action) => onApproveOrder(action)}
          createOrder={(_, action) => onCreateOrder(action)}
        />
      </PayPalScriptProvider>
    </div>
  );
};
