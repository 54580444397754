import { useState } from "react";
import { Application } from "../../schemas/applications";
import { Button, Checkbox, Image, Input, Textarea } from "@nextui-org/react";
import { stepType } from "../../schemas/types";

export const ApplicationDetails = ({
  details,
  onSubmitted,
}: {
  details: Application;
  onSubmitted: (data: Application, step: stepType) => void;
}) => {
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  return (
    <div className="flex flex-col gap-4">
      <section id="personal-details" className="flex flex-col gap-3">
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 items-center">
          <Image
            src={
              details?.photo
                ? URL.createObjectURL(details.photo)
                : "/avatar.jfif"
            }
            alt="applicant_photo"
            width={120}
            height={120}
            className="w-full col-span-1"
          />
          <div className="col-span-3 flex flex-col gap-3">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <Input
                isReadOnly={true}
                label="First name"
                value={details?.firstName}
              />
              <Input
                variant="bordered"
                isReadOnly={true}
                label="Last name"
                value={details?.lastName}
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <Input
                isReadOnly={true}
                label="Date of birth"
                value={details?.dob?.toDateString()}
              />
              <Input
                variant="bordered"
                isReadOnly={true}
                label="Gender"
                value={details?.gender}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
          <Input
            variant="bordered"
            isReadOnly={true}
            label="Marital status"
            value={details?.maritalStatus}
          />
          <Input isReadOnly={true} label="Height" value={details?.height} />
          <Input isReadOnly={true} label="Weight" value={details?.weight} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <Input
            variant="bordered"
            isReadOnly={true}
            label="Ethnicity"
            value={details?.ethnicity}
          />
          <Input
            isReadOnly={true}
            label="Country of origin"
            value={details?.countryOfOrigin}
          />
        </div>
        <Input
          isReadOnly={true}
          label="Current occupation"
          value={details?.currentOccupation}
        />
      </section>

      <section id="contact-details" className="flex flex-col gap-3">
        <p className="font-black text-sm text-slate-600 uppercase">
          Contact Information
        </p>
        <Input isReadOnly={true} label="Street" value={details?.street} />
        <Input isReadOnly={true} label="City/Town" value={details?.town} />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <Input
            variant="bordered"
            isReadOnly={true}
            label="State/Province"
            value={details?.state}
          />
          <Input isReadOnly={true} label="ZIP code" value={details?.zipCode} />
        </div>
        <Input isReadOnly={true} label="Country" value={details?.country} />
        <Input isReadOnly={true} label="Phone" value={details?.phone} />
        <Input isReadOnly={true} label="Email" value={details?.email} />
      </section>

      <section id="contact-details" className="flex flex-col gap-3">
        <p className="font-black text-sm text-slate-600 uppercase">
          Social Information
        </p>
        <Input
          isReadOnly={true}
          label="Instagram handle"
          value={details?.instagramHandle}
        />
        <Input
          isReadOnly={true}
          label="Facebook name"
          value={details?.facebookName}
        />

        <Input
          variant="bordered"
          isReadOnly={true}
          label="TikTok name"
          value={details?.tikTokName}
        />
        <Input
          isReadOnly={true}
          label="How did you hear about use?"
          value={details?.aboutUsSource}
        />

        <Textarea
          isReadOnly={true}
          label="About you"
          value={details?.description}
        />
      </section>

      <Checkbox
        onChange={({ target }) => setAcceptedTerms(target.checked)}
        className="font-semibold"
        color="success"
      >
        I acknowledge that all the information provided is valid and correct
      </Checkbox>

      <div className="mt-3 flex justify-center">
        <Button
          onPress={() => onSubmitted(details, "payment")}
          isDisabled={!acceptedTerms}
          size="lg"
          color="success"
          radius="sm"
          className="font-black uppercase text-slate-100"
        >
          Proceed to payment
        </Button>
      </div>
    </div>
  );
};
