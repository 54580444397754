import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useState } from "react";

export default function Index() {
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const terms: { title: string; content: string }[] = [
    {
      title: "Eligibility",
      content:
        "You confirm that you meet the eligibility requirements and agree to provide proof of eligibility upon request",
    },
    {
      title: "Rules and Regulations",
      content:
        "You agree to abide by all rules and regulations of the audition process as set forth by the organizers.",
    },
    {
      title: "Use of Likeness",
      content:
        "You grant the organizers the right to use your name, likeness, and any materials you submit for promotional purposes related to the audition",
    },
    {
      title: "Disqualification",
      content:
        "You understand that failure to adhere to these terms may result in disqualification from the audition process.",
    },
    {
      title: "Payment",
      content:
        "You understand that the application process requires a payment of an application fee. This fee is non-refundable under any circumstances, even if you decide to withdraw from the audition process or are disqualified for any reason.",
    },
    {
      title: "Changes to Terms",
      content:
        "The organizers reserve the right to change these terms at any time without notice. Please read and understand these terms before submitting your application",
    },
  ];

  return (
    <>
      <div className="relative min-h-screen h-screen w-screen bg-black bg-opacity-90 text-white">
        <img
          src="/bg.jpg"
          alt="bg_image"
          className="top-0 left-0 absolute h-full w-full object-cover -z-[1]"
        />
        <div className="sm:fixed flex sm:justify-center w-screen px-3 py-2 bg-black bg-opacity-90">
          <Link href="https://houseofcelebsafrica.org" className="gap-2">
            <img src="/logo512.png" alt="logo" width={35} />
            <h1 className="uppercase font-bold text-white">
              House of Celebs Africa
            </h1>
          </Link>
        </div>
        <div className="flex flex-col p-3 sm:justify-center items-center h-full w-full">
          <div className="sm:max-w-xl sm:text-center flex flex-col sm:items-center">
            <h2 className="text-xl sm:text-3xl uppercase font-black text-primary mb-3">
              Welcome to Our Online Audition Application Form Portal!
            </h2>
            <p className="mb-8 text-sm sm:text-base text-slate-300">
              We're thrilled that you've decided to take this step towards
              showcasing your talent. This is your moment to shine, and we can't
              wait to see what you have in store for us. Click the{" "}
              <em>
                <b>apply</b>
              </em>{" "}
              button below to fill out the form, and let's start this exciting
              journey together. Best of luck!
            </p>

            <code className="text-sm mb-3">
              By clicking "start application", you acknowledge that you have
              read, understood, and agreed to our{" "}
              <Link
                href="https://houseofcelebsafrica.org/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                <strong>privacy policy</strong>
              </Link>{" "}
              and{" "}
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowTerms(true);
                }}
              >
                <strong>terms of agreement.</strong>
              </Link>
            </code>

            <Button
              size="lg"
              radius="sm"
              color="primary"
              className="font-bold"
              as={Link}
              href="/apply"
            >
              START APPLICATION
            </Button>
          </div>
        </div>
      </div>

      <Modal
        scrollBehavior="outside"
        isOpen={showTerms}
        onClose={() => setShowTerms(false)}
      >
        <ModalContent>
          <ModalHeader className="font-bold flex justify-center items-center py-1">
            Terms of Agreement
          </ModalHeader>
          <ModalBody>
            <p className="text-sm font-semibold">
              By submitting this application form, you ("the Applicant") agree
              to the following terms:
            </p>
            <code>
              <ol className="list-decimal list-inside">
                {terms.map((term, index) => (
                  <li className="list-item mb-2 last:mb-0" key={`term${index}`}>
                    <strong>{term.title}: </strong>
                    <span>{term.content}</span>
                  </li>
                ))}
              </ol>
            </code>
          </ModalBody>
          <ModalFooter className="justify-center">
            <Button
              radius="sm"
              color="default"
              variant="flat"
              className="font-semibold"
              onClick={() => setShowTerms(false)}
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
